
.contactme-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contactme-title {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display');
    font-weight: bold;
    font-size: 2.2625vw;
    margin-top: 6vh;
    margin-right: 51.5vw;
    margin-bottom: 0;
}

.row-wrapper {
    display: flex;
    margin-top: 40px;
    height: 70vh;
    width: 63vw;
    flex-direction: row;
}

.form-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1.3;
}

.social-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: #4C8BF5;
    border-radius: 30px;
}

.social-title {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display');
    color: #FFFEFE;
    font-size: 2.2625vw;
    margin-left: 2vw;
    margin-top: 3vh;
    margin-bottom: 1vh;
}

.contact-label {
    margin-bottom: 10px;
    font-family: 'SF Pro Display';
    color: rgb(99, 99, 99);
    font-size: 1vw;
}

.contact-field {
    border-radius: 30px;
    border: 1px solid #E7DEDE;
    width: 25vw;
    height: 3.5vw;
    padding-left: 1.5vw;
    padding-right: 1.5vw;

    margin-bottom: 10px;
    margin-left: -1.0vw;
    outline: none;
    font-family: 'SF Pro Display';
    font-size: 1vw;
}

.contact-message {
    padding-top: 1vw;
    border-radius: 25px;
    margin-left: -0.7vw;
    border: 1px solid #E7DEDE;
    width: 25vw;
    height: 13vw;
    padding-left: 1.2vw;
    padding-right: 1.2vw;
    margin-bottom: 10px;
    resize: none;
    outline: none;
    font-family: 'SF Pro Display';
    font-size: 1vw;
}

.contact-field:focus {
    border-color: rgb(108, 108, 108, 0.7);
    transition: 0.5s;
}

.contact-message:focus {
    border-color: rgb(108, 108, 108, 0.7);
    transition: 0.5s;
}

.contact-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    border-radius: 70px;
    border: 2px solid #4C8BF5;
    width: 27.6vw;
    margin-left: -0.7vw;
    height: 4vw;
    color: #4C8BF5;
    background-color: white;
    font-size: 1.5vw;
    font-family: 'SF Pro Display';
    src: local('SF Pro Display');
    font-weight: bold;
    cursor: pointer;
    transition: 0.5s;
}


.contact-button:hover {
    background-color: #4C8BF5;
    color: white;
    transition: 0.5s;
}

.loader {
    margin-left: 0.8vw;
    border: 0.2vw solid #4C8BF5; 
    border-top: 0.2vw solid white; 
    border-radius: 50%;
    width: 1.3vw;
    height: 1.3vw;
    animation: spin 1.3s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .social-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: white;
    font-family: 'SF Pro Display';
    font-weight: bold;
    font-size: 1.5vw;
    margin-top: 2vh;
    margin-bottom: 2vh;
  }

  .social-logo {
    filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(319deg) brightness(103%) contrast(101%);    
    margin-left: 2vw;
    width: 3vw;
    height: 3vw;
    margin-right: 1vw;
    cursor: pointer;
    transition: 0.5s;
  }

  .social-logo:hover {
    transition: 0.5s;
    transform: scale(1.1);
  }

  .social-text {
    text-decoration: none;
    color: white;
    transition: 0.5s;
  }

  .social-text:hover {
    transition: 0.5s;
    transform: scale(1.1);
  }

  @media screen and (max-width: 1000px) {
    .contactme-title {
        font-size: 4vw;
    }

    .row-wrapper {
        width: 70vw;
        margin-top: 4vw;
    }

    .contact-label {
        font-size: calc(2 * 1vw);
    }

    .contact-field {
        border-radius: 30px;
        width: 30vw;
        height: 8vw;
        padding-left: 3vw;
        padding-right: 3vw;
        font-size: calc(2 * 1vw);
    }

    .contact-message {
        padding-top: 1vw;
        width: 30vw;
        height: 30vw;
        padding: 3vw 3vw 3vw 3vw;
        font-size: calc(2 * 1vw); 
    }

    .contact-button {
        margin-top: 20px;
        border-radius: 70px;
        border: 2px solid #4C8BF5;
        width: 35vw;
        height: 7vw;
        font-size: calc(2 * 1.5vw);
        margin-left: -0.7vw;
    }

    .social-wrapper {
        margin-top: 0vw;
    }
    
    .social-logo {
        width: 5vw;
        height: 5vw;
    }
    
    .social-title {
        font-size: calc(1.7 * 2.2625vw);
    }

    .social-text {
        font-size: calc(1.7 * 1.5vw);
    }
}

@media screen and (max-width: 800px) {
    .contactme-title {
        font-size: 4vw;
    }

    .row-wrapper {
        width: 70vw;
        margin-top: 4vw;
    }

    .contact-label {
        font-size: calc(2 * 1vw);
    }

    .contact-field {
        border-radius: 30px;
        width: 30vw;
        height: 5vw;
        padding-left: 3vw;
        padding-right: 3vw;
        font-size: calc(2 * 1vw);
    }

    .contact-message {
        padding-top: 1vw;
        width: 30vw;
        height: 30vw;
        padding: 3vw 3vw 3vw 3vw;
        font-size: calc(2 * 1vw); 
    }

    .contact-button {
        margin-top: 20px;
        border-radius: 70px;
        border: 2px solid #4C8BF5;
        width: 35vw;
        height: 7vw;
        font-size: calc(2 * 1.5vw);
        margin-left: -0.7vw;
    }

    .social-wrapper {
        margin-top: 0vw;
    }
    
    .social-logo {
        width: 5vw;
        height: 5vw;
    }
    
    .social-title {
        font-size: calc(1.7 * 2.2625vw);
    }

    .social-text {
        font-size: calc(1.7 * 1.5vw);
    }
}

@media screen and (max-width: 600px) {
    .contactme-title {
        font-size: 5vw;
        margin-left: 7vw;
    }

    .row-wrapper {
        flex-direction: column;
        width: 70vw;
        height: 20vw;
        margin-top: 4vw;
    }

    .social-wrapper {
        display: flex;
        flex-direction: column;
        flex: 1;
        background-color: #4C8BF5;
        border-radius: 30px;
        width: 60vw; 
    }

    .contact-label {
        font-size: calc(4 * 1vw);
    }


    .contact-field {
        border-radius: 30px;
        width: 70vw;
        height: calc(75/25 * 3.5vw);
        padding-left: 3vw;
        padding-right: 3vw;
        font-size: calc(4 * 1vw);
    }

    .contact-message {
        padding-top: 1vw;
        width: 70vw;
        height: 30vw;
        padding: 3vw 3vw 3vw 3vw;
        font-size: calc(4 * 1vw); 
    }

    .contact-button {
        margin-top: 20px;
        border-radius: 70px;
        border: 2px solid #4C8BF5;
        width: 70vw;
        height: 10vw;
        font-size: calc(3 * 1.5vw);
        margin-left: -0.7vw;
    }

    .social-wrapper {
        flex-direction: row;
        width: 70vw;
        height: 50vw;
        margin-top: 4vw;
    }
    
    .social-logo {
        margin-left: 3vw;
        width: 8vw;
        height: 8vw;
        margin-right: 3vw;
    }
    
    .social-title {
        display: none;        
    }

    .social-text {
        display: none;
    }

   
}

@media screen and (max-width: 500px) {
    .contactme-title {
        font-size: calc(1.2 * 6vw);
    }

    .row-wrapper {
        flex-direction: column;
        width: 80vw;
        height: 20vw;
        margin-top: 4vw;
    }

    .social-wrapper {
        display: flex;
        flex-direction: column;
        flex: 1;
        background-color: #4C8BF5;
        border-radius: 30px;
        width: 80vw; 
    }

    .contact-label {
        font-size: calc(4 * 1vw);
    }


    .contact-field {
        border-radius: 30px;
        width: calc(75/25 * 25vw);
        height: calc(75/25 * 3.5vw);
        padding-left: 3vw;
        padding-right: 3vw;
        font-size: calc(4 * 1vw);
    }

    .contact-message {
        padding-top: 1vw;
        width: 75vw;
        height: 30vw;
        padding: 3vw 3vw 3vw 3vw;
        font-size: calc(4 * 1vw); 
    }

    .contact-button {
        margin-top: 20px;
        border-radius: 70px;
        border: 2px solid #4C8BF5;
        width: 80vw;
        height: 10vw;
        font-size: calc(3 * 1.5vw);
        margin-left: -0.7vw;
    }

    .social-wrapper {
        flex-direction: row;
        width: 80vw;
        height: 50vw;
        margin-top: 4vw;
    }
    
    .social-logo {
        margin-left: 3vw;
        width: 8vw;
        height: 8vw;
        margin-right: 3vw;
    }
    
    .social-title {
        display: none;        
    }

    .social-text {
        display: none;
    }
    
}

@media screen and (max-width: 400px) {


    .contact-label {
        font-size: calc(5 * 1vw);
    }


    .contact-field {
        border-radius: 30px;
        width: calc(70/25 * 25vw);
        height: calc(90/25 * 3.5vw);
        padding-left: 5vw;
        padding-right: 5vw;
        font-size: calc(5 * 1vw);
    }

    .contact-message {
        padding-top: 1vw;
        width: 75vw;
        height: 30vw;
        padding: 3vw 3vw 3vw 3vw;
        font-size: calc(5 * 1vw); 
    }

    .contact-button {
        margin-top: 20px;
        border-radius: 70px;
        border: 2px solid #4C8BF5;
        width: 80vw;
        height: 15vw;
        font-size: calc(3 * 1.5vw);
        margin-left: -0.7vw;
    }

    .social-wrapper {
        flex-direction: row;
        width: 80vw;
        height: 50vw;
        margin-top: 4vw;
    }
    
    .social-logo {
        margin-left: 3vw;
        width: 8vw;
        height: 8vw;
        margin-right: 3vw;
    }
    
    .social-title {
        display: none;        
    }

    .social-text {
        display: none;
    }
    
}
