body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden;

}

/* Chrome version 29 and above */
@media screen and (-webkit-min-device-pixel-ratio:0)
and (min-resolution:.001dpcm) {
  ::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
    
  ::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 20px;
  }
  
  ::-webkit-scrollbar-track {
    background: #eee;
    border-radius: 20px;
  }
}

:root {
  --background: white;
  --text-primary: black;
  --text-secondary: #B6B6B6;
  --text-tertiary: rgb(183, 183, 183);
  --text-white: white;
  --accent: rgb(76, 139, 245);
  --accent-secondary: #189EFF;
  --accent-tertiary: #1247FF;

  --experience-desc: #4A4A4A;
  --experience-date: #706969;
  --experience-dash-line: #B8B3B3;

  --duration: 800ms;
	--iterations: 1;
}

[data-theme='dark'] {
  --background: #0A2541;
  --text-primary: white;
  --text-secondary: #ADBCCB;
  --text-tertiary: #ADBCCB;
  --text-white: white;
  --accent: #0D2E4E;
  --accent-secondary: #00D4FE;
  --accent-tertiary: #0b417b;
  
  
  --experience-desc: #dddddd;
  --experience-date: #b8b8b8;
  --experience-dash-line: #B8B3B3;
}





