
@font-face {
    font-family: 'Segoe UI';
    src: url('../../fonts/segoe-ui-4-cufonfonts-webfont/Segoe\ UI.woff');
    font-style: normal;
    font-weight: normal;
    font-display: block;

}

@font-face {
    font-family: 'Segoe UI';
    font-weight: bold;
    src: url('../../fonts/segoe-ui-4-cufonfonts-webfont/Segoe\ UI\ Bold.woff');
    font-display: block;

}

.slider {
    position: relative;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.slide {
    position: relative;
    min-width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    transition: 0.5s;
}

#goLeft {
    position: absolute;
    top: 50%;
    left: 1vw;
    transform: translateY(-50%);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: none;
    display: flex;
    justify-content: center;
    background-color: white;
    align-items: center;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

#goLeft:hover ~ .left-preview {
    visibility: visible;
    opacity: 1;
}

.left-preview {
    position: absolute;
    top: 50%;
    left: 1vw;
    transform: translateY(-50%);
    margin-top: 80px;
    right: 1.5vw;
    transform: translateY(-50%);
    text-align: left;
    font-family: 'Segoe UI';
    font-size: 20px;
    width: 100px;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.3s, opacity 0.3s linear;

}


#goRight {
    position: absolute;
    top: 50%;
    right: 1vw;
    transform: translateY(-50%);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: none;
    display: flex;
    justify-content: center;
    background-color: white;
    align-items: center;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

#goLeft:focus {
    outline: 0;
}

#goRight:focus {
    outline: 0;
}

#goRight:hover ~ .right-preview {
    visibility: visible;
    opacity: 1;
}

.right-preview {
    position: absolute;
    position: absolute;
    top: 50%;
    margin-top: 80px;
    right: 1.5vw;
    transform: translateY(-50%);
    text-align: right;
    font-family: 'Segoe UI';
    font-size: 20px;
    width: 100px;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.3s, opacity 0.3s linear;
}

.arrow-icon {
    width: 35px;
    height: 35px;
    color: #4C8BF5;
}

.slide-indicator {
    position: absolute;
    bottom: 4.5vh;
    height: 10px;
    left: 50vw;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
}

.indicator {
    width: 9px;
    height: 9px;
    margin-left: 4px;
    margin-right: 4px;
    background-color: #B4B4B4;
    cursor: pointer;
    border-radius: 50px;
    transition: all 0.3s;
}

.indicator:hover {
    transform: scale(1.4);
}

.hide {
    transition: 0.5s;
    display: none !important;
} 

#blue {
    background-color: #4C8BF5;
}

.bold {
    font-weight: bold;
}

.preview-text {
    margin-top: -17px;
}

@media screen and (max-width: 500px) {
    #goLeft {
        display: none;
    }

    #goRight {
        display: none;
    }

    .left-preview {
        display: none;
    }

    .right-preview {
        display: none;
    }
    
}