@font-face {
    font-family: 'SF Pro Display';
    src: url('../../../fonts/sf-pro-display-cufonfonts/SFPRODISPLAYREGULAR.woff2');
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'SF Pro Display';
    font-weight: bold;
    src: url('../../../fonts/sf-pro-display-cufonfonts/SFPRODISPLAYBOLD.woff2');
}

@font-face {
    font-family: 'SF Pro Display';
    font-weight: lighter;
    src: url('../../../fonts/sf-pro-display-cufonfonts/SF-Pro-Display-Light.woff2');
}

.skill-wrap {
    display: flex;
    flex-direction: column;
    margin: 0;
    margin-left: 4vw;
    width: 70%
}

.skill-item-wrap {
    display: flex;
    flex-direction: row;
    overflow-x: auto !important;
    transition: 0.5s;
}

.skill-title {
    font-family: 'SF Pro Display';
    font-weight: bold;
    font-size: 1.1vw;
    margin: 0;
    margin-top: 1.5vh;
    margin-bottom: 0.5vh;
}

.skill-item {
    background-color: #CBDDFF;
    font-family: 'SF Pro Display';
    font-weight: lighter;
    padding: 1vh 1vw 1vh 1vw;
    margin-right: 1vw;
    margin-top: 0.5vh;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2vh;
    border: 1px solid rgb(0, 87, 255, 0.56);
    border-radius: 50px;
    color: #3C8AD3;
    font-size: 1vw;
    white-space: nowrap;
}

@media screen and (max-aspect-ratio: 4/3) {
    .skill-title {
        font-size: calc(1.3 * 1.5vw);
    } 

    .skill-item {
        font-size: calc(1.3 * 1vw);
    }

    .skill-wrap {
        width: 85%;
    }
}

@media screen and (max-width: 1000px) {
    .skill-title {
        font-size: calc(1.4 * 1.5vw);
    } 

    .skill-item {
        font-size: calc(1.4 * 1vw);
    }

    .skill-wrap {
        width: 85%;
    }
}

@media screen and (max-width: 800px) {
    .skill-title {
        font-size: calc(1.5 * 1.5vw);
    } 

    .skill-item {
        font-size: calc(1.5 * 1vw);
    }

    .skill-wrap {
        width: 85%;
    }
}

@media screen and (max-width: 700px) {
    .skill-title {
        font-size: calc(1.8 * 1.5vw);
    } 

    .skill-item {
        font-size: calc(1.8 * 1vw);
    }

    .skill-wrap {
        width: 85%;
    } 
}

@media screen and (max-width: 600px) {
    .skill-title {
        font-size: calc(2 * 1.5vw);
    } 

    .skill-item {
        font-size: calc(2 * 1vw);
    }

    .skill-wrap {
        width: 85%;
    } 
 
}

@media screen and (max-width: 500px) {
    .skill-title {
        font-size: calc(2.5 * 1.5vw);
    } 

    .skill-item {
        font-size: calc(2.5 * 1vw);
    }

    .skill-wrap {
        width: 85%;
    }  
}

@media screen and (max-width: 400px) {
    .skill-title {
        font-size: calc(3.2 * 1.5vw);
    } 

    .skill-item {
        font-size: calc(3.2 * 1vw);
    }

    .skill-wrap {
        width: 90%;
    }  
}
