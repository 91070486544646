@font-face {
    font-family: 'SF Pro Display';
    src: url('../../../fonts/sf-pro-display-cufonfonts/SFPRODISPLAYREGULAR.woff2');
    font-style: normal;
    font-weight: normal;
    font-display: block;

}

@font-face {
    font-family: 'SF Pro Display';
    font-weight: bold;
    src: url('../../../fonts/sf-pro-display-cufonfonts/SFPRODISPLAYBOLD.woff2');
    font-display: block;
}


.exp-comp-wrapper {
    margin-left: 3.5vw;
    width: 80%;
    display: flex;
    flex-direction: row;
    margin-bottom: 0.4vh;
}

.img-bar-wrapper {
    width: 8%;
    align-items: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.company-logo-img {
    width: 60%;
    border-radius: 100%;
    border: 1px solid #CFD0D1;
    margin-bottom: 0.7vh;
    margin-top: 0.7vh;
    object-fit: cover;
}

.info-wrapper {
    width: 92%;
}

.info-role {
    margin: 0;
    margin-top: 0.7vh;
    font-family: 'SF Pro Display';
    font-weight: bold;
    font-size: 1.1vw;
}

.info-company {
    margin: 0;
    font-family: 'SF Pro Display';
    font-weight: normal;
    font-size: 1.00vw;
}

.info-date {
    margin: 0;
    margin-top: 0.1vw;
    font-family: 'SF Pro Display';
    font-weight: normal;
    font-size: 0.77vw;
    color: var(--experience-date);
}

.info-desc {
    font-family: 'SF Pro Display';
    font-weight: normal;
    font-size: 0.87vw;
    width: 90%;
    margin-top: 1vh;
    color: var(--experience-desc);
    white-space: pre-line;
}

.dash-line {
    height: 100%;
    width: 1.5px;
    background-color: var(--experience-dash-line);
}

@media screen and (max-aspect-ratio: 4/3) {
    .info-role {
        font-size: calc(1.2 * 1.6vw);
    }

    .info-company {
        font-size: calc(1.2 * 1.45vw);
    }
    
    .info-date {
        font-size: calc(1.2 * 1.12vw);
    }
    
    .info-desc {
        font-size: calc(1.2 * 1.265vw);
    } 
}

@media screen and (max-aspect-ratio: 3/4) {
    .info-role {
        font-size: 1.6vw;
    }

    .info-company {
        font-size: 1.45vw;
    }
    
    .info-date {
        font-size: 1.12vw;
    }
    
    .info-desc {
        font-size: 1.265vw;
    } 
}

@media screen and (max-width: 800px) {
    .info-role {
        font-size: calc(1.4 * 1.6vw);
    }

    .info-company {
        font-size: calc(1.4 * 1.45vw);
    }
    
    .info-date {
        font-size: calc(1.4 * 1.12vw);
    }
    
    .info-desc {
        font-size: calc(1.4 * 1.265vw);
    }
}

@media screen and (max-width: 700px) {
    .info-role {
        font-size: calc(1.6 * 1.6vw);
    }

    .info-company {
        font-size: calc(1.6 * 1.45vw);
    }
    
    .info-date {
        font-size: calc(1.6 * 1.12vw);
    }
    
    .info-desc {
        font-size: calc(1.6 * 1.265vw);
    }
}


@media screen and (max-width: 500px) {
    .info-role {
        font-size: calc(1.2 * 3.2vw);
    }

    .info-company {
        font-size: calc(1.2 * 2.9vw);
    }
    
    .info-date {
        font-size: calc(1.2 * 2.24vw);
    }
    
    .info-desc {
        font-size: calc(1.2 * 2.53vw);
    }

    .exp-comp-wrapper {
        width: 95%;
        margin-left: 2vw;
    }

    .dash-line {
        width: 0.2vw;
    }

    .img-bar-wrapper {
        width: 15%;
    }
}


