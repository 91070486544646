@font-face {
    font-family: 'SF Pro Display';
    src: url('../../fonts/sf-pro-display-cufonfonts/SFPRODISPLAYREGULAR.woff2');
    font-style: normal;
    font-weight: normal;
    font-display: block;

}

@font-face {
    font-family: 'SF Pro Display';
    font-weight: bold;
    src: url('../../fonts/sf-pro-display-cufonfonts/SFPRODISPLAYBOLD.woff2');
    font-display: block;

}

@font-face {
    font-family: 'SF Pro Display';
    font-weight: lighter;
    src: url('../../fonts/sf-pro-display-cufonfonts/SF-Pro-Display-Light.woff2');
    font-display: block;

}

.home-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    background-color: var(--background);
}

.swipe-info {
    display: none;
}

.text-wrapper {
    height: 100%;
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    color: var(--text-primary);
}

.graphic-wrapper {
    width: 55%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.keyboard-keys {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bottom-row {
    display: flex;
    flex-direction: row;
    height: 5vw;
}

.key {
    width: 4vw;
    height: 4vw;
    margin: 0 0.2vw 0 0.2vw;
    filter: brightness(0) invert(1);
    opacity: 0;
    animation: reveal linear 1s 1 forwards;
    animation-delay: 1.2s;
}

.right-key {
    animation: reveal linear 1s 1 forwards,
               buttonClick linear 2s infinite;
    animation-delay: 1.2s;
}

.blue-square {
    width: 32.7vw;
    height: 32.7vw;
    border-radius: 60px;
    background-color: var(--accent);
    transform: translateX(-15%);
    background: linear-gradient(45deg, #32a6ff 0%, var(--accent) 49%, #8d54ff 82%);
    background-size: 200%;
    animation: aurora 10s infinite;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.keyboard-text {
    font-family: 'SF Pro Display', Arial, Helvetica, sans-serif;
    color: white;
    font-size: 1vw;
    opacity: 0;
    animation-name: reveal;
    animation-delay: 1s;
    animation-duration: 0.7s;
    animation-fill-mode: forwards;
}

.blue-inner {
    background: radial-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.15));
    background-size: 200%;
    animation: aurora 10s infinite;
    width: 32.7vw;
    height: 32.7vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@keyframes buttonClick {
    0% {
        margin-top: 0;
    }

    50% {
        margin-top: 0.5vw;
    }

    100% {
        margin-top: 0;
    }
}

@keyframes reveal {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes aurora {
    0% {
        background-position: left top;
        border-radius: 50%;
    }
    25% {
        background-position: right top;
        border-radius: 30%;
    }
    50% {
        background-position: right bottom;
        border-radius: 15%;
    }
    75% {
        background-position: left bottom;
        border-radius: 30%;
    }
    100% {
        background-position: left top;
        border-radius: 50%;
    }
}


.intro-text {
    font-family: 'SF Pro Display', Arial, Helvetica, sans-serif;
    transform: translate(0, -10%);
    animation-name: reveal;
    opacity: 0;
    animation-delay: 0.7s;
    animation-duration: 0.7s;
    animation-fill-mode: forwards;
}

.hi-manu {
    font-size: 4.2vw;
    font-weight: bold;

}

.desc-manu {
    font-size: 2.625vw;
    color: var(--text-secondary);
    font-weight: 300;
    transform: translateY(-2.35vw);
    font-weight: lighter;
}

.blue {
    background: none;
    color: var(--accent-secondary);
}

@media screen and (max-width:800px) {
    .graphic-wrapper {
        display: none;
    }

    .home-wrapper {
        justify-content: center;
        align-items: center;
    }

    .intro-text {    
        transform: translate(0, 0);
    }

    .text-wrapper {
        padding: none;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .hi-manu {
        font-size: 9vw;
        width: 60vw;
    }
    
    .desc-manu {
        font-size: 5.2vw;
        transform: translateY(-5vw);
    }

}

@media screen and (max-width:500px) {
    .graphic-wrapper {
        display: none;
    }

    .home-wrapper {
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .intro-text {    
        transform: translate(0, 0);
    }

    .text-wrapper {
        padding: none;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .hi-manu {
        font-size: 12vw;
        width: 60vw;
    }
    
    .desc-manu {
        font-size: 7vw;
        transform: translateY(-6vw);
    }

    .swipe-info {
        position: absolute;
        display: block;
        left: 22vw;
        top: 90vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        color: var(--text-tertiary);
        font-family: 'SF Pro Display', Arial, Helvetica, sans-serif;
        -webkit-animation: cssAnimation 8s forwards; 
        animation: cssAnimation 8s forwards;
    }

    @keyframes cssAnimation {
        0%   {opacity: 1;}
        90%  {opacity: 1;}
        100% {opacity: 0;}
    }
    @-webkit-keyframes cssAnimation {
        0%   {opacity: 1;}
        90%  {opacity: 1;}
        100% {opacity: 0;}
    }
}