
@font-face {
    font-family: 'SF Pro Display';
    src: url('../../fonts/sf-pro-display-cufonfonts/SFPRODISPLAYREGULAR.woff2');
    font-style: normal;
    font-weight: normal;
    font-display: block;
}

@font-face {
    font-family: 'SF Pro Display';
    font-weight: bold;
    src: url('../../fonts/sf-pro-display-cufonfonts/SFPRODISPLAYBOLD.woff2');
    font-display: block;

}

.experience-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    background-color: var(--background);
    color: var(--text-primary);
}

.resume-wrapper {
    display: flex;
    flex-direction: column;
    width: 45%;
    height: 100%;
    background-color: var(--accent);
    align-items: center;
    justify-content: center;
}

.resume {
    margin-right: -2vw;
    width: 35vw;
    height: 45.33vw;
    border-radius: 20px;
    transition: 0.5s;
}

.download-resume {
    padding: 0.9vw 2vw 0.9vw 2vw;
    border-radius: 40px;
    background-color: var(--accent-tertiary);
    color: var(--text-white);
    text-decoration: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1vw;
    font-family: 'SF Pro Display';
    font-weight: bold;
    font-size: 1.51vw;
    transition: 0.5s;
}

.download-resume:hover {
    transform: scale(1.1);
    transition: 0.5s;
}

.download-symbol {
    margin: 0;
    margin-left: 0.5vw;
}

.skills-wrapper {
    display: flex;
    flex-direction: column;
    width: 55%;
    height: 100%;
    display: flex;    
}

.exp-title {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display');
    font-weight: bold;
    font-size: 2.2625vw;
    margin-top: 6vh;
    margin-bottom: 1vh;
    margin-left: 3.5vw;
    color: var(--text-primary);
}

.descriptions-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 55%;
    margin: 0;
    margin-bottom: 3vh;
}

.mobile {
    display: none;
}

@media screen and (min-aspect-ratio: 2/1) {
    .resume {
        width: 23.33vw;
        height: 35vw;
    }
}

@media screen and (max-aspect-ratio: 4/3) {
    .experience-wrapper {
        justify-content: center;
        align-items: center;
    }

    .resume-wrapper {
        display: none;
    }

    .exp-title {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .download-resume {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 1.5vw;
        padding: 0.5vw 2vw 0.5vw 2vw;
        height: 2vw;
        margin-top: 0;
        margin-left: 30vw;
        background-color: white;
        color: gray;
        border: 1px solid gray;
        white-space: nowrap;
    }

    .mobile {
        display: block;
    }

    .skills-wrapper {
        width: 70%;
    }

    .skills {
        width: 100%;
    }

    .exp-title {
        font-size: calc(45/80 * 4.2vw);
    }
}

@media screen and (max-width: 800px) {
    .experience-wrapper {
        justify-content: center;
        align-items: center;
    }

    .resume-wrapper {
        display: none;
    }

    .skills-wrapper {
        width: 70%;
    }

    .skills {
        width: 100%;
    }

    .exp-title {
        font-size: 4vw;
    }

    .download-resume {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: calc(1.5 * 1.5vw);
        padding: 0.5vw 2vw 0.5vw 2vw;
        height: calc(1.5 * 2vw);
        margin-top: 0;
        margin-left: 15vw;
        background-color: white;
        color: gray;
        border: 1px solid gray;
        white-space: nowrap;
    }
}

@media screen and (max-width: 700px) {
    .experience-wrapper {
        justify-content: center;
        align-items: center;
    }

    .resume-wrapper {
        display: none;
    }

    .skills-wrapper {
        width: 70%;
    }

    .skills {
        width: 100%;
    }

    .exp-title {
        font-size: 4vw;
    }

    .download-resume {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: calc(1.8 * 1.5vw);
        padding: 0.5vw 2vw 0.5vw 2vw;
        height: calc(1.8 * 2vw);
        margin-top: 0;
        margin-left: 10vw;
        background-color: white;
        color: gray;
        border: 1px solid gray;
        white-space: nowrap;
    }
}

@media screen and (max-width: 600px) {
    .experience-wrapper {
        justify-content: center;
        align-items: center;
    }

    .resume-wrapper {
        display: none;
    }

    .skills-wrapper {
        width: 80%;
    }

    .skills {
        width: 100%;
    }

    .exp-title {
        font-size: 5vw;
    }

    .download-resume {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: calc(1.8 * 1.5vw);
        padding: 0.5vw 2vw 0.5vw 2vw;
        height: calc(1.8 * 2vw);
        margin-top: 0;
        margin-left: 5vw;
        background-color: white;
        color: gray;
        border: 1px solid gray;
        white-space: nowrap;
    }
}

@media screen and (max-width: 500px) {
    .experience-wrapper {
        justify-content: center;
        align-items: center;
    }

    .resume-wrapper {
        display: none;
    }

    .skills-wrapper {
        width: 85%;
    }

    .skills {
        width: 100%;
    }

    .exp-title {
        font-size: calc(1.2 * 6vw);
    }
}

