
@font-face {
    font-family: 'Sen';
    src: url('../../fonts/sen/sen-regular.otf');
    font-style: normal;
    font-weight: normal;
    font-display: block;

}


a:link {
    text-decoration: none;
}

.projects-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.proj-title {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display');
    font-weight: bold;
    font-size: 2.2625vw;
    margin-top: 6vh;
    margin-right: 51.5vw;
    margin-bottom: 0;
}

.proj-list {
    display: flex;
    flex-direction: column;
    margin-top: 1.5vh;
    margin-bottom: 10vh;
    padding-right: 2vw;
    width: 60vw;
    overflow-y: auto;
    overflow-x: hidden;
}

.proj-wrapper {
    display: flex;
    flex-direction: row;
    border: 1px solid #C5C5C5;
    border-radius: 2vw;
    margin-top: 2.5vh;
    height: 11vw;
    z-index: 10;
    background-color: white;
}

.proj-text-wrapper {
    display: flex;
    flex-direction: column;
    margin-left: 1vw;
    margin-top: 0;
}

.proj-img {
    width: 11vw;
    height: 11vw;
    border-radius: 2vw 0 0 2vw;
    border-right: 1px  solid #C5C5C5;
    object-fit: cover;
}

.proj-box-title {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display');
    font-weight: bold;
    font-size: 2.2625vw;
    margin-bottom: 0;
    margin-top: 1vw;
}

.proj-desc {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display');
    font-size: 1.03vw;
    font-weight: 300;
    color: #818181;
    margin: 0;
    margin-top: 0.2vw;
    width: 47vw;
}

.btn-row {
    display: flex;
    flex-direction: row;
    margin-top: 0.9vw;
}

.code-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    border-radius: 0.4vw;
    padding-top: 0.52vw;
    padding-bottom: 0.52vw;
    padding-left: 0.5vw;
    padding-right: 2.3vw;
    transition: 0.5s;
    height: 1.4vw;
}


.code-button:hover {
    background-color: black;
}

.code-button:hover .code-text {
    color: white;
}

.github-logo {
    width: 1.4vw;
    height: 1.4vw;
    border-radius: 3vw;
}


.code-text {
    font-family: 'Sen';
    color: black;
    font-size: 1.1vw;
    margin-left: 0.5vw;
}

.demo-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    border-radius: 0.4vw;
    padding-top: 0.52vw;
    padding-bottom: 0.52vw;
    padding-left: 0.5vw;
    padding-right: 2.3vw;
    transition: 0.5s;
    margin-left: 1vw;
    height: 1.4vw;
}

.demo-button:hover {
    background-color: #2BC20E;
    border-color: #2BC20E;
}

.demo-button:hover .demo-text {
    color: white;
}

.demo-logo {
    width: 1.1vw;
    height: 1.1vw;
    border-radius: 3vw;
    mix-blend-mode: multiply;
}

.demo-text {
    font-family: 'Sen';
    color: black;
    font-size: 1.1vw;
    margin-left: 0.5vw;
}


@media screen and (max-aspect-ratio: 4/3) {
    .proj-title {
        font-size: calc(45/80 * 4.2vw);
    }
}

@media screen and (max-width: 1000px) {
    .proj-list {
        width: 60vw;
    }
}


@media screen and (max-width: 800px) {
    .proj-title {
        font-size: 4vw;
    }
    
    .proj-list {
        width: 65vw;
        margin-top: 1vw;
    }

    .proj-wrapper {
        height: 25vw;
        border-radius: 3vw;
    }

    .proj-img {
        width: 20vw;
        height: 21vw;
        object-fit: cover;
        border-radius: 3vw 0vw 0vw 3vw;
        border-right: 1px  solid #C5C5C5;
    }

    .proj-text-wrapper {
        margin-left: 2vw;
        margin-top: 0.5vw;
    }

    .proj-box-title {
        font-size: calc(1.5 * 2.2625vw);
        margin-bottom: 0;
        margin-top: 1vw;
    }
    
    .proj-desc {
        font-size: calc(1.7 * 1.03vw);
        font-weight: 300;
        color: #818181;
        margin: 0;
        margin-top: 0.2vw;
        width: 40vw;
    }
    
    .btn-row {
        margin-top: 1vw;
    }

    .code-button {
        width: 10vw;
        height: 2vw;
        margin-right: 1vw;
        padding: 1vw 1vw;
    }

    .demo-button {
        width: 10vw;
        height: 2vw; 
        padding: 1vw 1vw;
    }

    .code-text {
        font-size: calc(1.7 * 1.1vw);
        margin: 0;
     }

     .demo-text {
        font-size: calc(1.7 * 1.1vw);
        margin: 0;
     }
     
     .demo-logo {
        display: none;
     }

     .github-logo {
        display: none;
     }
}

@media screen and (max-width: 600px) {
    .proj-title {
        font-size: 5vw;
    }
}

@media screen and (max-width: 500px) {
    .proj-title {
        font-size: calc(1.2 * 6vw);
    }


    .proj-list {
        margin-top: 1vw;
        width: 80vw;
    }

    .proj-wrapper {
        height: 60vw;
        border-radius: 5vw;
    }

    .proj-img {
        width: 25vw;
        height: 60vw;
        object-fit: cover;
        border-radius: 5vw 0vw 0vw 5vw;
        border-right: 1px  solid #C5C5C5;
    }

    .proj-text-wrapper {
        margin-left: 3vw;
        padding-top: 1vw
    }

    .proj-box-title {
        font-size: calc(2.5 * 2.2625vw);
        margin-bottom: 0;
        margin-top: 1vw;
    }
    
    .proj-desc {
        font-size: calc(3.2 * 1.03vw);
        font-weight: 300;
        color: #818181;
        margin: 0;
        margin-top: 0.2vw;
        width: 47vw;
    } 
    
    .btn-row {
        margin-top: 3vw;
    }

    .code-button {
        width: 15vw;
        height: 6vw;
        margin-right: 1vw;
        padding: 1vw 1vw;
    }

    .demo-button {
        width: 15vw;
        height: 6vw; 
        padding: 1vw 1vw;
    }

    .github-logo {
        display: none;
    }

    .code-text {
        font-size: calc(3 * 1.1vw);
        margin: 0;
     }

     .demo-text {
        font-size: calc(3 * 1.1vw);
        margin: 0;
     }

     .demo-logo {
        display: none;
     }
    
}


.circles li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(76, 139, 245, 0.2);
    animation: animate 25s linear infinite;
    bottom: 0px;
    z-index: -100;
    opacity: 0;
}

ul {
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
}


.circles li:nth-child(1){
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}


.circles li:nth-child(2){
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3){
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6){
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7){
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8){
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9){
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}



@keyframes animate {

    0%{
        transform: translateY(-1000px) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100%{
        transform: translateY(0) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}
